
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { Info } from '@/types/buildFacilities'

@Component({
  components: { MapLocation },
  filters: {
    isNetRed (value: string) {
      return value ? value === '1'
        ? '是' : value === '0'
          ? '否' : '' : ''
    }
  }
})

export default class BuildDetail extends Vue {
  private info: Info = {
    projectId: '',
    buildName: '',
    buildDate: '',
    description: '',
    resourceList: [],
    resourcesLocationList: []
  }

  created () {
    console.log(this.$route)
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.buildFacilities.selectBuildFacilitiesByBuildId, {
      buildId: this.$route.params.buildId
    }).then(res => {
      this.info = res || {}
    })
  }
}
